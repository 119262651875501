.vimeo-wrapper iframe {
  height: 42.6vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  width:  100vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: relative;
  filter: brightness(90%); 
}

.BANNER{
  width:100%;
}

.line_change h6{
  margin-bottom: 1.25rem;
}

.line_change h4{
  margin-bottom: 1.25rem;
}

.line_change h5{
  margin-bottom: 1.25rem;
}

/* GENERAL */
.section{
  padding-top: 3rem;
}

.section_1{
  padding-top: 1rem;
}

.section_2{
  padding-top: 2rem;
}

.valign-wrapper{
  flex-wrap: wrap;
}

p{
  text-align: justify;
}
.spacing{
  letter-spacing: 0.15rem;
}

.justify{
  text-align: justify;
}

.line{
  background-color: orange;
  height: 0.2rem;
  margin-top: -10px;
}

.other_logo{
  height: 7.5rem;
  position: absolute;
  top: 9rem;
  right: -1rem;
  transform: translate(-50%, -50%);
}

.left_pad_2{
  padding-left: 2rem;
}

/* HOME */

.main_logo{
  height: 13rem;
  position: absolute;
  top: 17rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_logo_med{
  height: 12rem;
  position: absolute;
  top: 14rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_logo_sm{
  height: 6rem;
  position: absolute;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_links{
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.main_name{
  height: 3.5rem;
  position: absolute;
  top: 3rem;
  left: 2rem;
}

.main_name_med{
  height: 3rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.main_name_sm{
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.home_container{
  width: 90%;
}
/* ANIMATION */
.ani_disp{
  position: absolute;
  top: 75%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 45%;
}

/* TEAM IMAGES */
.Testi_image{
  height: 8.5rem;
}

.NAMES H6{
  font-size: 0.9rem;
}
/* FOOTER */
.FOOTER_ITEMS{
  padding: 1rem;
  color: white;
  font-weight: 400;
  padding-top: 1rem !important;
}

.FOOTER_ITEMS_MED{
  padding: 0.4rem;
  color: white;
  font-weight: 400;
  font-size: x-small;
}